import { Col, Container, Row } from 'react-bootstrap';
import { selectProlificId } from '../../../../store/auth/auth.slice';
import { useSelector } from 'react-redux';
import { useVideo } from '../../StudentVideo';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RingLoader } from 'react-spinners';

const PostTest = () => {
  const { video } = useVideo();

  //Loading state
  const [isLoading, setIsLoading] = useState(true);

  const prolificId = useSelector(selectProlificId);
  const posttestURL = video.posttestURL ? video.posttestURL + '?PROLIFIC_PID=' + prolificId : undefined;
  const navigate = useNavigate();
  const homePageURL = '/student';

  const iframeStyle = {
    width: '100%',
    height: '100vh',
  };

  const onLoadIframe = () => {
    setIsLoading(false);
  };

  const onErrorIframe = (e: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
    console.log(e);
    setIsLoading(false);
  };

  // If the pretest URL is not available, redirect to home page
  useEffect(() => {
    if (!posttestURL) navigate(homePageURL, { replace: true });
  }, [posttestURL, navigate, homePageURL]);

  return (
    <Container className='py-4'>
      <Row>
        <Col>
          <h2>Post-Test</h2>
          <p>Answer the following questions to the best of your knowledge. Each question needs to be answered. You will have to scroll down within the questionnaire to see all questions. Once you have answered all questions click on the blue arrow in the bottom right corner. After submitting your answers you will see the completion code needed to complete your submission on Prolific</p>
          <div className='mb-4' />
          {isLoading && <IFrameLoadingSpinner />}
          {posttestURL && <iframe style={iframeStyle} id='post-test-iframe' title='Post-test' width='100%' height='100%' src={posttestURL} onLoad={onLoadIframe} onError={onErrorIframe} />}
        </Col>
      </Row>
    </Container>
  );
};

const IFrameLoadingSpinner = () => {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center py-5'>
      <RingLoader color='#5eb2f2' size='10rem' />
      <p className='text-center fw-bold text-primary mt-3'>Loading post-test...</p>
    </div>
  );
};

export default PostTest;
